.header-search-container {
  .search-btn-super {
    display: flex;
    justify-content: flex-end;
    .show-all-control{
      width: 36px;
      color: #1890ff;
      font-size: 16px;
      cursor: pointer;
      line-height: 32px;
    }
  }
}