.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/admin/login-page-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .lgform {
    width: 420px;
    padding: 20px 40px;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/admin/login-form-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius:5px;
    .lgtitle {
      text-align: center;
      margin: 12px 0 14px 0;
    }
  }
}
