.organ-container {
  .line {
    height: 16px;
    background: #f0f2f5;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-bottom: 20px;
  }
  .table-btn-super {
    padding-bottom: 20px;
    display: flex;
  }
}
