.main-layout-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  .left-log {
    height: 64px;
    padding-left: 27px;
    overflow: hidden;
    white-space: nowrap;
    a {
      height: 100%;
      display: flex;
      align-items: center;
    }
    span.title {
      color: #fff;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .middle-tab {
    display: flex;
    justify-content: center;
    color: #fff;
  }
  .right-info {
    display: flex;
    justify-content: flex-end;
  }
}